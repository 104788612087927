// export const BASE_URL = "http://localhost:8080";
export const BASE_URL = "https://api.baejournal.com";
const API_ROOT = `${BASE_URL}/api/v1`;

// Auth Webservices
export const LOGIN = `${API_ROOT}/authentication/signin`;
export const FORGET_PASSWORD = `${API_ROOT}/forgetPassword`;

// Author Webservices
export const GET_ALL_AUTHORS = `${API_ROOT}/users/authors`;

// Reviewers Webservices
export const GET_ALL_REVIEWERS = `${API_ROOT}/users/reviewers`;
export const ADD_REVIEWER = `${API_ROOT}/users/create`;
export const PROFILE_INFO = `${API_ROOT}/users/profile`;
export const ACTIVATE_USER = `${API_ROOT}/users/unblock`;
export const BLOCK_USER = `${API_ROOT}/users/block`;

// Editors Webservices
export const GET_ALL_EDITORS = `${API_ROOT}/users/editors`;

// Emails Webservices
export const GET_ALL_EMAILS = `${API_ROOT}/mail/get-all-mailes`;
export const APPROVE_EMAIL = `${API_ROOT}/mail/approve-mail`;

// Submission Webservices
export const GET_ALL_SUBMISSIONS = `${API_ROOT}/files/get-submissions`;
export const GET_SUBMISSION_BY_ID = `${API_ROOT}/files/get-submission`;
export const ASSIGN_REVIEWERS = `${API_ROOT}/files/assign-reviewers`;
export const GET_INVITATION_BY_ID = `${API_ROOT}/files/get-invitation-details`;

// Issues Webservices
export const GET_ALL_VOLS = `${API_ROOT}/issues/volume-issues`;
export const GET_ALL_ISSUES = `${API_ROOT}/issues/admin`;
export const ISSUES = `${API_ROOT}/issues`;
export const HIDE_SHOW_ISSUES = `${API_ROOT}/issues/hide-show`;
export const DELETE_ISSUE = `${API_ROOT}/issues/delete-issue`;
export const ADD_ARTICLE_ISSUE = `${API_ROOT}/issues/add-article-to-issue`;
export const DELETE_JOURNAL_ISSUE = `${API_ROOT}/issues/delete-article-from-issue`;
export const REORDER_ISSUE = `${API_ROOT}/issues/reorder`;
