import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";

import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import * as submissionService from "../services/submission.service";
import * as issuesService from "../services/issues.service";
import { Routes } from "../routes";

const AddNewIssue = () => {
  const history = useHistory();
  const [submissionList, setSubmissionList] = useState([]);
  const [options, setOptions] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleBack = () => {
    history.goBack();
  };

  const getAllSubmissionList = async () => {
    try {
      const result = await submissionService.getSumbission();
      const options = result.map((item) => ({
        value: item.files_id,
        label: item.files_articleCode,
      }));
      setSubmissionList(result);
      setOptions(options);
    } catch (error) {}
  };

  useEffect(() => {
    getAllSubmissionList();
  }, []);

  const createNewIssue = async (payload) => {
    const values = selectedOptions.map((item) => item.value);
    const formData = new FormData();
    formData.append("coverStory", payload.coverStory);
    formData.append("image", payload.image);
    formData.append("fileIds ", values);
    try {
      const result = await issuesService.addIssue(formData);
      history.push({ pathname: Routes.AllIssues.path });
    } catch (error) {}
  };

  return (
    <div>
      <Button className="mb-2" onClick={handleBack}>
        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
      </Button>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Add New Issue</h5>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="coverStory">
                  <Form.Label>Cover Story*</Form.Label>
                  <Controller
                    control={control}
                    name="coverStory"
                    rules={{
                      required: {
                        value: true,
                        message: "The Cover Story field is mandatory",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        as="textarea"
                        required
                        type="text"
                        placeholder="Cover Story"
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.coverStory && (
                    <Form.Text className="error-text">
                      {errors.coverStory.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  control={control}
                  name="image"
                  rules={{ required: "Please select a image " }}
                  render={({ field }) => (
                    <Form.Control
                      type="file"
                      onChange={(e) => field.onChange(e.target.files[0])}
                      isInvalid={field.state && !!field.state.errors?.image}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.image?.message}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="formBasicMultiSelect">
                  <Form.Label>Select your journals</Form.Label>
                  <Select
                    isMulti
                    options={options}
                    value={selectedOptions}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="block-btns-stepper">
              <div className="mt-3">
                <Button
                  variant="primary"
                  onClick={handleSubmit(createNewIssue)}
                >
                  Add
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddNewIssue;
