import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import {
    faCheck,
    faCog,
    faDownload,
    faHome,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
    Col,
    Row,
    Form,
    Button,
    Card,
    Table,
    FormCheck,
    Alert,
} from "@themesberg/react-bootstrap";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { defaultOptions } from '../utils/functions/httpMerthods';
import { BASE_URL } from '../utils/constants/apis';


const ReviewerResponseDetails = () => {

    const { fileId, userId } = useParams()
    const [reviewData, setReviewData] = useState([]);
    console.log(reviewData)
    const [editorComment, setEditorComment] = useState();
    const [editorFile, setEditorFile] = useState();
    const [authorFile, setAuthorFile] = useState();
    console.log(authorFile)
    console.log(editorFile)





    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function removeAppPrefix(url) {
        return url.replace(/\/app\//, '/');
    }

    const getPDFFile = async () => {
        try {
            const attachmentEditor = await reviewData?.attachmentEditor;
            const attachmentAuthor = await reviewData?.attachmentAuthor;
            console.log(attachmentAuthor)
            console.log(removeAppPrefix(attachmentAuthor))
            if (attachmentEditor) {
                setEditorFile(`${BASE_URL}/${removeAppPrefix(attachmentEditor)}`);
            }
            if (attachmentAuthor) {
                setAuthorFile(`${BASE_URL}/${removeAppPrefix(attachmentAuthor)}`);
            }
        } catch (error) { }
    };

    useEffect(() => {
        getPDFFile();
    }, [reviewData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.baejournal.com/api/v1/files/get-one-reviwed-submission/${fileId}/${userId}`, defaultOptions());

                setReviewData(response.data);
                // Handle response data here
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [userId, fileId]);

    const renderRating = (rating) => {
        if (reviewData && reviewData[rating]) {
            const parsedRating = JSON.parse(reviewData[rating]);
            return `${parsedRating.rating} `;
        }
        return '';
    };

    const handleEditorCommentFileChange = (event) => {
        setEditorComment(event.target.files[0]);
    };

    return (
        <>
            <div style={{ margin: "40px" }}>
                <Row>
                    <div style={{ marginTop: "25px" }}>
                        <h4>Reviewer FeedBack Details</h4>
                    </div>
                    <Form.Group>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td style={{ position: "relative" }}>Originality and academic contribution to the field</td>
                                    <td>{renderRating('originalityAndContribution')}</td>
                                </tr>
                                <tr>
                                    <td style={{ position: "relative" }}>Concision and clarity.</td>
                                    <td>{renderRating('concisionAndClarity')}</td>
                                </tr>
                                <tr>
                                    <td style={{ position: "relative" }}>Relevance of the used methods to the manuscript objectives.</td>
                                    <td>{renderRating('relevanceOfMethods')}</td>
                                </tr>
                                <tr>
                                    <td style={{ position: "relative" }}>Coherence of discussion and conclusion with the results.</td>
                                    <td>{renderRating('coherenceOfDiscussion')}</td>
                                </tr>
                                <tr>
                                    <td style={{ position: "relative" }}>Adequateness of the cited references.</td>
                                    <td>{renderRating('adequatenessOfReferences')}</td>
                                </tr>
                                <tr>
                                    <td style={{ position: "relative" }}>Language quality.</td>
                                    <td>{renderRating('languageQuality')}</td>
                                </tr>
                                <tr>
                                    <td style={{ position: "relative" }}>Quality of the figures and the tables.</td>
                                    <td>{renderRating('qualityOfFiguresAndTables')}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h6 style={{ textAlign: "right" }}>Totale Score : {reviewData.score}</h6>
                    </Form.Group>
                </Row>
                <Row className="justify-content-between align-items-center">
                    <h5 className="mb-4">Comments</h5>
                    <Form>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group id="abstract">
                                    <h6>Reviewer comment : </h6>
                                    <Form.Label>{reviewData?.commentsToAuthors == "undefined" ? ("") : reviewData?.commentsToAuthors}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        {reviewData.attachmentAuthor == null ? ("") : (
                            <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
                                <p>Click here to read the pdf</p>
                                <a href={authorFile} rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        style={{ marginLeft: 10, cursor: "pointer" }}
                                        icon={faDownload}
                                    />
                                </a>
                            </h6>
                        )}
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group id="abstract">
                                    <h6>Editor comment : </h6>
                                    <Form.Label> {reviewData?.commentsToEditor == "undefined" ? ("") : reviewData?.commentsToEditor} </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        {reviewData.attachmentEditor == null ? ("") : (
                            <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
                                <p>Click here to read the pdf</p>
                                <a href={editorFile} rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        style={{ marginLeft: 10, cursor: "pointer" }}
                                        icon={faDownload}
                                    />
                                </a>
                            </h6>
                        )}
                    </Form>
                </Row>
                <Row className="justify-content-between align-items-center" style={{ marginBottom: "20px" }}>
                    <h6>Recommendation : </h6>
                    <Form.Group>
                        <div>
                            <h5>
                                {reviewData.recommendation}
                            </h5>
                        </div>
                    </Form.Group>
                </Row>
            </div>
        </>
    )
}

export default ReviewerResponseDetails