import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  ButtonGroup,
  Dropdown,
  Button,
} from "@themesberg/react-bootstrap";
import { AllSubmissionTable } from "../components/AllSubmissionTable";
import * as submissionService from "../services/submission.service";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";

export default () => {
  const [submissionList, setSubmissionList] = useState([]);
  const history = useHistory();

  const handleRedirect = () => {
    history.push({ pathname: Routes.AddJournal.path });
  };

  const getAllSubmissionList = async () => {
    try {
      const result = await submissionService.getSumbission();
      setSubmissionList(result);
    } catch (error) {}
  };

  useEffect(() => {
    getAllSubmissionList();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>All Journals</h4>
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mr-0 mt-0 mb-0 m-3">
        <p></p>
        <div>
          <Button variant="secondary" onClick={handleRedirect}>
            Add New Journal
          </Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      {submissionList && <AllSubmissionTable files={submissionList} />}
    </>
  );
};
