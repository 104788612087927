import { defaultOptions, get } from "../utils/functions/httpMerthods";
import * as urlWebservice from "../utils/constants/apis";

export const getAllAuthors = async () => {
  try {
    const result = await get(urlWebservice.GET_ALL_AUTHORS, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
