import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { defaultOptions } from "../utils/functions/httpMerthods";
import {
  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
  Form,
} from "@themesberg/react-bootstrap";
import { Controller, useForm } from "react-hook-form";

export default () => {
  const [data, setData] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [checkedUserId, setCheckedUserId] = useState([]);
  const [showFeedbackModal, setShowShowFeedbackModal] = useState(false);

  const handleOpenFeedbackModal = () => {
    setShowShowFeedbackModal(true);
  };

  const handleFeedbackModalClose = () => {
    setShowShowFeedbackModal(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  console.log(id);
  const history = useHistory();
  const handleRedirect = (path) => {
    history.push({ pathname: path });
  };

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const response = await fetch(
          `https://api.baejournal.com/api/v1/files/get-reviwed-submissions/${id}`,
          defaultOptions()
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(id);
  }, []);

  const handleCheckboxChange = (event, userId) => {
    const checked = event.target.checked;
    setIsCheckboxChecked(checked);
    if (checked) {
      setCheckedUserId((prevState) => [...prevState, userId]);
    } else {
      setCheckedUserId((prevState) => prevState.filter((id) => id !== userId));
    }
  };

  const setUserId = () => {
    console.log(checkedUserId);
    // Add your logic to send feedback to the author with the IDs stored in checkedUserId
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Reviewer Feedback</h4>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center"></Row>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: "15px",
        }}
      >
        <Button
          variant="secondary"
          className="text-gray"
          disabled={!isCheckboxChecked}
          // onClick={setUserId}
          // onClick={() => handleRedirect(`admin-feedback/5/3`)}
          onClick={handleOpenFeedbackModal}
        >
          Send feedback to author
        </Button>
      </div> */}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                {/* <th className="border-bottom"></th> */}
                <th className="border-bottom">Article Code</th>
                <th className="border-bottom">Full Name</th>
                <th className="border-bottom">Email</th>
                <th className="border-bottom">Degree</th>
                <th className="border-bottom">score</th>
                <th className="border-bottom">review decision</th>
              </tr>
            </thead>
            <tbody>
              {data.map((el, index) => (
                <tr key={index}>
                 {/* <td>
                     <input
                      type="checkbox"
                      style={{
                        width: "70%",
                        marginTop: "0.5rem",
                        height: "18px",
                      }}
                      onChange={(event) =>
                        handleCheckboxChange(event, el.user.id)
                      }
                    />
                  </td> */}
                  <td
                    onClick={() =>
                      handleRedirect(
                        `reviewer-feedback-details/${id}/${el.user.id}`
                      )
                    }
                  >
                    <span className="fw-normal">{el.file.articleCode}</span>
                  </td>
                  <td
                    onClick={() =>
                      handleRedirect(
                        `reviewer-feedback-details/${id}/${el.user.id}`
                      )
                    }
                  >
                    <span className="fw-normal">
                      {el.user.first_name} {el.user.last_name}
                    </span>
                  </td>
                  <td
                    onClick={() =>
                      handleRedirect(
                        `reviewer-feedback-details/${id}/${el.user.id}`
                      )
                    }
                  >
                    <span className="fw-normal">{el.user.email}</span>
                  </td>
                  <td
                    onClick={() =>
                      handleRedirect(
                        `reviewer-feedback-details/${id}/${el.user.id}`
                      )
                    }
                  >
                    <span className="fw-normal">{el.user.degree}</span>
                  </td>
                  <td
                    onClick={() =>
                      handleRedirect(
                        `reviewer-feedback-details/${id}/${el.user.id}`
                      )
                    }
                  >
                    <span className="fw-normal">{el.score}</span>
                  </td>
                  <td
                    onClick={() =>
                      handleRedirect(
                        `reviewer-feedback-details/${id}/${el.user.id}`
                      )
                    }
                  >
                    <span className="fw-normal">{el.recommendation}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal
        as={Modal.Dialog}
        centered
        show={showFeedbackModal}
        onHide={handleFeedbackModalClose}
      >
        <Modal.Header>
          {/* <Modal.Title className="h6">Add New Feedback</Modal.Title> */}
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleFeedbackModalClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-between align-items-center">
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="abstract">
                    <Form.Label>Comments to the Authors*</Form.Label>
                    <Controller
                      control={control}
                      name="commentsToAuthors"
                      // defaultValue={submissionStore.abstract}
                      rules={{
                        required: {
                          value: true,
                          message:
                            "The Comments to the Authors field is mandatory",
                        },
                      }}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <Form.Control
                          as="textarea"
                          required
                          type="text"
                          placeholder="Comments to the Authors"
                          {...field}
                        />
                      )}
                    />
                    {/* {errors && errors?.commentsToAuthors && (
                                    <Form.Text className="error-text">
                                        {errors.commentsToAuthors.message}
                                    </Form.Text>
                                )} */}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-gray">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
