import axios from "axios";

export function defaultOptions() {
  const token = localStorage.getItem("ojs-dasboard-access_token");
  const globalSettings = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return globalSettings;
}

// Get method
export const get = async (url, options) => {
  try {
    const { data } = await axios.get(url, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs-dasboard-access_token");
      window.location.href = "/";
    }
    throw new Error(error);
  }
};

export const post = async (url, payload, options) => {
  try {
    const { data } = await axios.post(url, payload, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs-dasboard-access_token");
      window.location.href = "/";
    }
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};

export const put = async (url, payload, options) => {
  try {
    const { data } = await axios.put(url, payload, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs-dasboard-access_token");
      window.location.href = "/";
    }
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
export const patch = async (url, payload, options) => {
  try {
    const { data } = await axios.patch(url, payload, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs-dasboard-access_token");
      window.location.href = "/";
    }
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};

// delete method
export const deleteHttp = async (url, options) => {
  try {
    const { data } = await axios.delete(url, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs-dasboard-access_token");
      window.location.href = "/";
    }
    throw new Error(error);
  }
};
// delete method with payload
export const deleteHttpWithPayload = async (url, payload, options) => {
  try {
    const token = localStorage.getItem("ojs-dasboard-access_token");

    const { data } = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        payload,
      },
    });
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs-dasboard-access_token");
      window.location.href = "/";
    }
    throw new Error(error);
  }
};
