import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faUserCheck,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import * as usersService from "../services/user.service";

function UsersTable(props) {
  const { users } = props;
  const totalUsers = users.length;

  const TableRow = (props) => {
    const { id, index, first_name, last_name, email, role, status } = props;
    const statusVariant =
      status === "active"
        ? "success"
        : status === "pending"
        ? "warning"
        : status === "inactive"
        ? "danger"
        : "primary";

    const activateUser = async () => {
      try {
        await usersService.activateUser(id);
        window.location.reload();
      } catch (err) {}
    };
    const blockUser = async () => {
      try {
        await usersService.blockUser(id);
        window.location.reload();
      } catch (err) {}
    };

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {index + 1}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{first_name}</span>
        </td>
        <td>
          <span className="fw-normal">{last_name}</span>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
        <td>
          <span className="fw-normal role-text-styling">{role}</span>
        </td>
        <td>
          <span className={`role-text-styling fw-normal text-${statusVariant}`}>
            {status}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="text-success" onClick={activateUser}>
                <FontAwesomeIcon icon={faUserCheck} className="me-2" />
                Activate
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={blockUser}>
                <FontAwesomeIcon icon={faUserSlash} className="me-2" /> Block
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">First Name</th>
              <th className="border-bottom">Last Name</th>
              <th className="border-bottom">Email</th>
              <th className="border-bottom">Role</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            
            {users.length!==0 && users.map((item, index) => {
              let user = {
                ...item,
                index,
              };
              return <TableRow key={index} {...user} />;
            })}
          </tbody>
        </Table>
        {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalUsers}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
}

export default UsersTable;
