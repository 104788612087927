import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Alert,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import * as patterns from "../../utils/constants/patterns";
import * as reviewersService from "../../services/reviewers.service";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { countries } from "../../utils/constants";

export const ReviewerForm = () => {
  const history = useHistory();
  const [objectAlert, setObjectAlert] = useState({
    open: false,
    variant: "danger", // warning,danger,success
    message: "",
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Create = async (body) => {
    try {
      let payload = {
        ...body,
        role: "reviewer",
        title: body.title,
        degree: body.degree,
        user_name: body.first_name,
        country: body.country,
      };
      const result = await reviewersService.addReviewer(payload);
      if (result && result.message) {
        setObjectAlert({
          open: true,
          variant: "warning",
          message: result.message,
        });
      } else {
        history.push({ pathname: Routes.Reviewers.path });
      }
    } catch (error) {
      setObjectAlert({
        open: true,
        variant: "danger",
        message: "Something went wrong! Please Try again!",
      });
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      {objectAlert.open && (
        <Alert
          variant={objectAlert.variant}
          style={{
            textAlign: "center",
          }}
        >
          {objectAlert.message}
        </Alert>
      )}
      <Card.Body>
        <h5 className="mb-4">New Reviewer</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Controller
                  control={control}
                  name="first_name"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The First Name field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your first name"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.first_name && (
                  <Form.Text className="error-text">
                    {errors.first_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Controller
                  control={control}
                  name="last_name"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Last Name field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Also your last name"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.last_name && (
                  <Form.Text className="error-text">
                    {errors.last_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The email field is mandatory",
                    },
                    pattern: {
                      value: patterns.regExpEmail,
                      message: "The email field should include '@' and '.'",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@company.com"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.email && (
                  <Form.Text className="error-text">
                    {errors.email.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Controller
                  control={control}
                  name="phone"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Phone field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="number"
                      placeholder="+12-345 678 910"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.phone && (
                  <Form.Text className="error-text">
                    {errors.phone.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="title" className="mb-4">
                <Form.Label>Title</Form.Label>
                <InputGroup>
                  <Controller
                    control={control}
                    name="title"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "The Title field is mandatory",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="Title"
                        {...field}
                      />
                    )}
                  />
                </InputGroup>
                {errors && errors?.title && (
                  <Form.Text className="error-text">
                    {errors.title.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="degree" className="mb-4">
                <Form.Label>Degree</Form.Label>
                <InputGroup>
                  <Controller
                    control={control}
                    name="degree"
                    defaultValue=""
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="Degree"
                        {...field}
                      />
                    )}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Country field is mandatory",
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control as="select" {...field}>
                      <option value="">Select a country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
                {errors && errors?.country && (
                  <Form.Text className="error-text">
                    {errors.country.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3">
            <Button variant="primary" onClick={handleSubmit(Create)}>
              Create
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
