import React from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";

import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const AddNewJournal = () => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const handleBack = () => {
    history.goBack();
  };
  return (
    <div>
      <Button className="mb-2" onClick={handleBack}>
        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
      </Button>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Add New Journal</h5>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="name">
                  <Form.Label>Name*</Form.Label>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: {
                        value: true,
                        message: "The name field is mandatory",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        required
                        type="text"
                        placeholder="Name"
                        {...field}
                      />
                    )}
                  />
                  {/* {errors && errors?.title && (
                  <Form.Text className="error-text">
                    {errors.title.message}
                  </Form.Text>
                )} */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="description">
                  <Form.Label>Description*</Form.Label>
                  <Controller
                    control={control}
                    name="description"
                    rules={{
                      required: {
                        value: true,
                        message: "The Description field is mandatory",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        as="textarea"
                        required
                        type="text"
                        placeholder="Description"
                        {...field}
                      />
                    )}
                  />
                  {/* {errors && errors?.abstract && (
                  <Form.Text className="error-text">
                    {errors.abstract.message}
                  </Form.Text>
                )} */}
                </Form.Group>
              </Col>
            </Row>

            <div className="block-btns-stepper">
              <div className="mt-3">
                <Button
                  variant="primary"
                  //    onClick={handleSubmit(handleNextPage)}
                >
                  Add
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddNewJournal;
