import React from "react";

import { useEffect } from "react";
import { useState } from "react";
import * as submissionService from "../services/submission.service";
import { useParams } from "react-router-dom";
import { Row } from "@themesberg/react-bootstrap";
import EmailsSubmission from "../components/EmailsSubmission";

export default () => {
  const [data, setData] = useState();
  const { id } = useParams();

  const getSubmissionById = async () => {
    try {
      const result = await submissionService.getSumbissionById(id);
      setData(result.authors);
    } catch (error) {}
  };

  useEffect(() => {
    getSubmissionById();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Emails Submission</h4>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center"></Row>
      </div>

      {data && <EmailsSubmission users={data} />}
    </>
  );
};
