import {
  defaultOptions,
  get,
  patch,
  post,
} from "../utils/functions/httpMerthods";
import * as urlWebservice from "../utils/constants/apis";

export const getAllReviewers = async () => {
  try {
    const result = await get(urlWebservice.GET_ALL_REVIEWERS, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const addReviewer = async (payload) => {
  try {
    const result = await post(
      urlWebservice.ADD_REVIEWER,
      payload,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const assignReviewer = async (id, payload) => {
  try {
    const result = await patch(
      `${urlWebservice.ASSIGN_REVIEWERS}/${id}`,
      payload,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
