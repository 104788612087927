import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Modal,
  Form,
  Alert,
} from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as issueService from "../services/issues.service";
import { BASE_URL } from "../utils/constants/apis";
import { defaultOptions } from "../utils/functions/httpMerthods";

export default () => {
  const [showDefault, setShowDefault] = useState(false);
  const [data, setData] = useState([]);
  const [IssueData, setIssueData] = useState();
  const [newCoverValue, setNewCoverValue] = useState();

  console.log(newCoverValue, 'hereeee')

  console.log(data, 'hehehe')
  const [ImgURL, setImgURL] = useState(null);
  console.log(ImgURL, 'hehehehhdada')
  const [order, setOrder] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedAuthorId, setSelectedAuthorId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const fileInputRef = useRef(null);
  const [newAuthorData, setNewAuthorData] = useState({
    first_name: "",
    last_name: "",
    degree: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAuthorData({ ...newAuthorData, [name]: value });

    // Validate input
    let error = "";
    if (!value.trim()) {
      error = "This field is required";
    } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      error = "Please enter a valid email address";
    }
    setErrors({ ...errors, [name]: error });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };


  const handleOpenModal = (fileId) => {
    setSelectedFileId(fileId);
    setShowDefault(true);
  };

  const handleClose = () => setShowDefault(false);

  const handleCoverValue = (e) => {
    setNewCoverValue(
      e.target.value,
    );
  }

  const handleNewAuthorSubmit = async () => {
    const validationErrors = {};
    Object.keys(newAuthorData).forEach((key) => {
      if (!newAuthorData[key].trim()) {
        validationErrors[key] = "This field is required";
      } else if (key === "email" && !/\S+@\S+\.\S+/.test(newAuthorData[key])) {
        validationErrors[key] = "Please enter a valid email address";
      }
    });

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/mail/add-author/${selectedFileId}`,
        newAuthorData,
        defaultOptions()
      );
      if (response.status === 200 || response.status === 201) {
        console.log("author added successfully");
        setShowSuccess(true);
        setNewAuthorData({
          first_name: "",
          last_name: "",
          degree: "",
          email: "",
        });
        handleClose();
        getIssueById();
        setTimeout(() => setShowSuccess(false), 3000);
      }
    } catch (error) {
      console.log("error adding author", error);
    }
  };

  const handleRedirect = (path) => {
    history.push({ pathname: path });
  };

  const getIssueById = async () => {
    try {
      const result = await issueService.getIssueById(id);
      setIssueData(result);
      setNewCoverValue(result?.coverStory);
      setImgURL(`${BASE_URL}/${result?.imageUrl}`);  
      setData(result.files);
    } catch (error) {
      console.log("error fetching issue by id", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    getIssueById();
  }, []);

  useEffect(() => {
    const transformedArray = data.map((item, index) => ({
      id: item.id,
      order: index,
    }));
    setOrder(transformedArray);
  }, [data]);

  const moveUp = (index) => {
    if (index === 0) return;
    const newArray = [...data];
    [newArray[index], newArray[index - 1]] = [
      newArray[index - 1],
      newArray[index],
    ];
    setData(newArray);
  };

  const moveDown = (index) => {
    if (index === data.length - 1) return;
    const newArray = [...data];
    [newArray[index], newArray[index + 1]] = [
      newArray[index + 1],
      newArray[index],
    ];
    setData(newArray);
  };

  const moveAuthorUp = async (fileIndex, authorIndex) => {
    if (authorIndex === 0) return;
    const newFilesArray = [...data];
    const authors = [...newFilesArray[fileIndex].otherAuthors];
    const tempOrder = authors[authorIndex].order;
    authors[authorIndex].order = authors[authorIndex - 1].order;
    authors[authorIndex - 1].order = tempOrder;

    // Reorder on server
    try {
      await axios.post(
        `${BASE_URL}/api/v1/mail/reorder/${newFilesArray[fileIndex].id}`,
        { ids: authors.map((author) => author.id) },
        defaultOptions()
      );
      [authors[authorIndex], authors[authorIndex - 1]] = [
        authors[authorIndex - 1],
        authors[authorIndex],
      ];
      newFilesArray[fileIndex].otherAuthors = authors;
      setData(newFilesArray);
    } catch (error) {
      console.log("error reordering authors", error);
    }
  };

  const moveAuthorDown = async (fileIndex, authorIndex) => {
    if (authorIndex === data[fileIndex].otherAuthors.length - 1) return;
    const newFilesArray = [...data];
    const authors = [...newFilesArray[fileIndex].otherAuthors];
    const tempOrder = authors[authorIndex].order;
    authors[authorIndex].order = authors[authorIndex + 1].order;
    authors[authorIndex + 1].order = tempOrder;

    // Reorder on server
    try {
      await axios.post(
        `${BASE_URL}/api/v1/mail/reorder/${newFilesArray[fileIndex].id}`,
        { ids: authors.map((author) => author.id) },
        defaultOptions()
      );
      [authors[authorIndex], authors[authorIndex + 1]] = [
        authors[authorIndex + 1],
        authors[authorIndex],
      ];
      newFilesArray[fileIndex].otherAuthors = authors;
      setData(newFilesArray);
    } catch (error) {
      console.log("error reordering authors", error);
    }
  };

  const handleOpenDeleteModal = (fileId, authorId) => {
    setSelectedFileId(fileId);
    setSelectedAuthorId(authorId);
    setShowDeleteModal(true);
  };

  const handleDeleteAuthor = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/v1/mail/delete/${selectedFileId}/${selectedAuthorId}`,
        defaultOptions()
      );
      if (response.status === 200) {
        console.log("author deleted successfully");
        setShowDeleteModal(false);
        getIssueById();
      }
    } catch (error) {
      console.log("error deleting author", error);
    }
  };

  const reOrderIssue = async () => {
    try {
      // First PUT request to update the issue
      await axios.put(
        `${BASE_URL}/api/v1/issues/update-issue/${IssueData.id}`,
        {
          coverStory: newCoverValue,
          imageUrl: ImgURL
        },
        defaultOptions()
      );

      // Second request to reorder the issue (already existing)
      await issueService.reoarderIssue(order);
      handleRedirect("/all-issues");
    } catch (error) {
      console.log("error reordering issue", error);
    }
  };

  return (
    <Container className="px-0">
      <Row>
        <Col xs={12} className="p-3">
          <h1 className="h2" id="overview">
            Issue Details
          </h1>
          <textarea
            type="text"
            value={newCoverValue}
            onChange={handleCoverValue}
            className="form-control mb-2"
            placeholder="Message"
            name="message"
            aria-label="Subscribe form"
            required
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
           <input type="file" accept="image/*" style={{ display: 'none' }} ref={fileInputRef} onChange={handleImageUpload}/>
            {ImgURL ? (

              <Card.Img
                onClick={triggerFileInput}
                variant="top"
                src={ImgURL}
                style={{ height: "280px", width: "20%", borderRadius: "15px" }}
              />
            ) : (
              ""
            )}
          </div>
          {showSuccess && (
            <Alert
              variant="success"
              onClose={() => setShowSuccess(false)}
              dismissible
            >
              Author added successfully!
            </Alert>
          )}
          {data.length === 0 ? (
            <h4 className="h4" id="overview" style={{ textAlign: "center" }}>
              No Data
            </h4>
          ) : (
            data.map((file, fileIndex) => (
              <Card className="mt-2" key={file.id}>
                <Card.Body>
                  <article>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Title : </span>
                      {file.title}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Abstract : </span>
                      {file.abstract}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Article Type : </span>
                      {file.articleType}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">File : </span>
                      {file.filePath && file.mergedFile && (
                        <a
                          href={`${BASE_URL}/${file.filePath}/${file.mergedFile}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <FontAwesomeIcon
                            style={{ marginLeft: 10, cursor: "pointer" }}
                            icon={faDownload}
                          />
                        </a>
                      )}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Authors : </span>
                      {file.originalAuthorLastName}
                      {file.otherAuthors.length === 1 && (
                        <>
                          {file.otherAuthors.map((item, authIdx) => (
                            <span key={authIdx} className="p-marign-left">
                              - {item.last_name}
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                className={`me-2 p-marign-left ${authIdx === 0
                                    ? "disabled-pointer-class"
                                    : "pointer-class"
                                  }`}
                                onClick={() => moveAuthorUp(fileIndex, authIdx)}
                              />
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className={`me-2 p-marign-left ${authIdx === file.otherAuthors.length - 1
                                    ? "disabled-pointer-class"
                                    : "pointer-class"
                                  }`}
                                onClick={() =>
                                  moveAuthorDown(fileIndex, authIdx)
                                }
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() =>
                                  handleOpenDeleteModal(file.id, item.id)
                                }
                                className="me-2 p-marign-left"
                              />
                            </span>
                          ))}
                        </>
                      )}
                      {file.otherAuthors.length > 1 && (
                        <span className="p-marign-left">& All</span>
                      )}
                    </p>
                    <Button
                      variant="outline-primary"
                      onClick={() => moveUp(fileIndex)}
                      disabled={fileIndex === 0}
                      style={{ marginLeft: "10px" }}
                    >
                      Up
                      <FontAwesomeIcon icon={faArrowUp} className="me-2" />
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => moveDown(fileIndex)}
                      disabled={fileIndex === data.length - 1}
                      style={{ marginLeft: "10px" }}
                    >
                      Down
                      <FontAwesomeIcon icon={faArrowDown} className="me-2" />
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => handleOpenModal(file.id)}
                      style={{ marginLeft: "10px" }}
                    >
                      Add Author
                      <FontAwesomeIcon className="me-2" />
                    </Button>
                  </article>
                </Card.Body>
              </Card>
            ))
          )}
          {data.length > 0 && (
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mr-0 mt-4 mb-4 m-3">
              <p></p>
              <div>
                <Button variant="primary" onClick={reOrderIssue}>
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Add author</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="first_name"
                onChange={handleChange}
                value={newAuthorData.first_name}
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.first_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="lastName">
              <Form.Label style={{ marginTop: "12px" }}>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="last_name"
                onChange={handleChange}
                value={newAuthorData.last_name}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="email">
              <Form.Label style={{ marginTop: "12px" }}>Email</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                onChange={handleChange}
                value={newAuthorData.email}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="degree">
              <Form.Label style={{ marginTop: "12px" }}>Degree</Form.Label>
              <Form.Control
                required
                type="text"
                name="degree"
                onChange={handleChange}
                value={newAuthorData.degree}
                isInvalid={!!errors.degree}
              />
              <Form.Control.Feedback type="invalid">
                {errors.degree}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            className="text-gray"
            type="submit"
            onClick={handleNewAuthorSubmit}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="h6">Confirm Deletion</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => setShowDeleteModal(false)}
          />
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this author?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteAuthor}>
            Yes
          </Button>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
