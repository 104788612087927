import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Table,
  FormCheck,
  Button,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { defaultOptions } from "../utils/functions/httpMerthods";
import { BASE_URL } from "../utils/constants/apis";
import { useHistory } from "react-router-dom";

const EditorFeedback = () => {
  const { id } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [editorComment, setEditorComment] = useState();
  const [selectedOptionRecommandation, setSelectedOptionRecommandation] =
    useState("Accepted without revision");
  const [selectedOptionDecisionPhrase, setSelectedOptionDecisionPhrase] =
    useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [adminComments, setAdminComments] = useState("");
  const [phrases, setPhrases] = useState([]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleOptionRecommandationChange = (value) => {
    setSelectedOptionRecommandation(value);
  };

  const handleOptionDecisionPhraseChange = (value) => {
    setSelectedOptionDecisionPhrase(value);
  };

  const handlePhrasesChange = (checked, value) => {
    let newPhrases;
    if (checked) {
      newPhrases = [...phrases, value];
    } else {
      newPhrases = phrases.filter((phrase) => phrase !== value);
    }
    setPhrases(newPhrases);

    setAdminComments(newPhrases.join("\n"));
  };

  useEffect(() => {
    setAdminComments(phrases.join("\n"));
  }, [phrases]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.baejournal.com/api/v1/files/get-reviwed-submissions/${id}`,
          defaultOptions()
        );
        setReviewData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const history = useHistory();

  // const [reviwersComments, setReviewersComment] = useState([
  //     {
  //         score: "",
  //         reviwerComments: "",
  //         attachmentReviwerComments: "",
  //         editorComments: "",
  //         attachmentEditorComments: "",
  //         recommandation: ""
  //     }
  // ])

  function removeAppPrefix(url) {
    return url.replace(/^\/app\//, "/");
  }

  const renderRating = (jsonString) => {
    try {
      const parsed = JSON.parse(jsonString);
      return parsed.rating;
    } catch (e) {
      console.error("Error parsing JSON", e);
      return "";
    }
  };

  const handleEditorCommentFileChange = (event) => {
    setEditorComment(event.target.files[0]);
  };

  const [selectedData, setSelectedData] = useState([{}]);

  const handleCheckboxChange = (index, isChecked, key, value) => {
    setSelectedData((prev) => {
      const newData = [...prev]; // Clone the previous state
      if (isChecked) {
        if (!newData[index]) {
          newData[index] = {}; // Create a new object if it doesn't exist
        }
        newData[index][key] = value; // Set the value for the specified key
      } else {
        if (newData[index] && newData[index][key]) {
          delete newData[index][key]; // Remove the key if unchecked
          if (Object.keys(newData[index]).length === 0) {
            delete newData[index]; // Remove the object if empty
          }
        }
      }
      return newData.filter((item) => item); // Filter out any undefined or empty items
    });
  };

  const onSubmit = async (data) => {
    const userIds = reviewData.map((item) => item.user.id);
    const submissionData = {
      userIds,
      admincommentsToAuthors: adminComments,
      editorDecision: selectedOptionRecommandation,
      // phrases: phrases
      reviwersComments: selectedData,
    };
    console.log(submissionData);
    try {
      const response = await axios.put(
        `https://api.baejournal.com/api/v1/files/submit-revision-to-author/${id}`,
        submissionData,
        defaultOptions()
      );
      console.log("Submission successful:", response.data);
      setShowSuccessMessage(true);

      setTimeout(() => {
        setShowSuccessMessage(false);
        history.push("/all-submissions");
      }, 3000);
    } catch (error) {
      console.error("Error submitting revision:", error);
    }
  };

  return (
    <div style={{ margin: "40px" }}>
      {reviewData.map((el, index) => (
        <div>
          <Row>
            <div style={{ marginTop: "25px", display: "flex", gap: "10px" }}>
              <h4>
                Response of reviewer {index + 1} - {el.user.last_name}{" "}
                {el.user.first_name} - {el.user.email}{" "}
              </h4>
              {el?.score ? (
                <Form.Check
                  type="checkbox"
                  label="Include this reviewer's score"
                  onChange={(e) =>
                    handleCheckboxChange(
                      index,
                      e.target.checked,
                      "score",
                      el.score
                    )
                  }
                />
              ) : (
                ""
              )}
            </div>
            <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
              <p>Would you like to receive the revision of this manuscript?</p>
              <p className="underline-text p-marign-left">
                <Form.Check
                  type="radio"
                  id="defaultCheck-1"
                  className="me-2"
                  checked={el.revisionReceived}
                  label="Yes"
                />
              </p>
              <p className="p-marign-left"> /</p>
              <p className="underline-text p-marign-left">
                {" "}
                <Form.Check
                  type="radio"
                  id="defaultCheck-2"
                  checked={!el.revisionReceived}
                  label="No"
                  className="mb-0"
                />
              </p>
            </h6>
            <Form.Group>
              <Table bordered>
                <tbody>
                  <tr>
                    <td style={{ position: "relative" }}>
                      Originality and academic contribution to the field
                    </td>
                    <td>{renderRating(el.originalityAndContribution)}</td>
                  </tr>
                  <tr>
                    <td style={{ position: "relative" }}>
                      Concision and clarity.
                    </td>
                    <td>{renderRating(el.concisionAndClarity)}</td>
                  </tr>
                  <tr>
                    <td style={{ position: "relative" }}>
                      Relevance of the used methods to the manuscript
                      objectives.
                    </td>
                    <td>{renderRating(el.relevanceOfMethods)}</td>
                  </tr>
                  <tr>
                    <td style={{ position: "relative" }}>
                      Coherence of discussion and conclusion with the results.
                    </td>
                    <td>{renderRating(el.coherenceOfDiscussion)}</td>
                  </tr>
                  <tr>
                    <td style={{ position: "relative" }}>
                      Adequateness of the cited references.
                    </td>
                    <td>{renderRating(el.adequatenessOfReferences)}</td>
                  </tr>
                  <tr>
                    <td style={{ position: "relative" }}>Language quality.</td>
                    <td>{renderRating(el.languageQuality)}</td>
                  </tr>
                  <tr>
                    <td style={{ position: "relative" }}>
                      Quality of the figures and the tables.
                    </td>
                    <td>{renderRating(el.qualityOfFiguresAndTables)}</td>
                  </tr>
                </tbody>
              </Table>
              <h6 style={{ textAlign: "right" }}>Totale Score : {el.score}</h6>
            </Form.Group>
          </Row>
          <Row className="justify-content-between align-items-center">
            <h5 className="mb-4">Comments</h5>
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="abstract">
                    <div style={{ display: "flex", gap: "20px" }}>
                      <h6>Reviewer comment:</h6>
                      {el.commentsToAuthors != "undefined" ? (
                        <Form.Check
                          type="checkbox"
                          label="Include this reviewer comment"
                          onChange={(e) =>
                            handleCheckboxChange(
                              index,
                              e.target.checked,
                              "reviewerComments",
                              el.commentsToAuthors
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <Form.Label>
                      {el.commentsToAuthors || "No comments provided"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="abstract">
                    <div style={{ display: "flex", gap: "20px" }}>
                      <h6>Editor comment:</h6>
                      {el.commentsToEditor != "undefined" ? (
                        <Form.Check
                          type="checkbox"
                          label="Include this editor comment"
                          onChange={(e) =>
                            handleCheckboxChange(
                              index,
                              e.target.checked,
                              "editorComments",
                              el.commentsToEditor
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <Form.Label>
                      {el.commentsToEditor == "undefined"
                        ? ""
                        : el.commentsToEditor}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              {el.attachmentAuthor && (
                <div>
                  <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
                    <p>
                      Click here to read the {el.user.first_name}'s PDF file to
                      the author
                    </p>
                    <a
                      href={`${BASE_URL}/${removeAppPrefix(
                        el.attachmentAuthor
                      )}`}
                      target=""
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        style={{ marginLeft: 10, cursor: "pointer" }}
                      />
                    </a>
                    {el.attachmentAuthor != "undefined" ? (
                      <Form.Check
                        type="checkbox"
                        label="Include this author file"
                        style={{ marginLeft: "20px" }}
                        onChange={(e) =>
                          handleCheckboxChange(
                            index,
                            e.target.checked,
                            "attachmentAuthor",
                            el.attachmentAuthor
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                  </h6>
                </div>
              )}
              {el.attachmentEditor && (
                <>
                  <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
                    <p>
                      Click here to read the {el.user.first_name}'s PDF file to
                      the editor
                    </p>
                    <a
                      href={`${BASE_URL}/${removeAppPrefix(
                        el.attachmentEditor
                      )}`}
                      target=""
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        style={{ marginLeft: 10, cursor: "pointer" }}
                      />
                    </a>
                    {el.attachmentEditor != "undefined" ? (
                      <Form.Check
                        type="checkbox"
                        label="Include this editor file"
                        style={{ marginLeft: "20px" }}
                        onChange={(e) =>
                          handleCheckboxChange(
                            index,
                            e.target.checked,
                            "attachmentEditor",
                            el.attachmentEditor
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                  </h6>
                </>
              )}
            </Form>
          </Row>
          <Row
            className="justify-content-between align-items-center"
            style={{ marginBottom: "20px" }}
          >
            <div style={{ display: "flex", gap: "20px" }}>
              <h6>Recommendation:</h6>
              <Form.Check
                type="checkbox"
                label="Include this recommendation"
                onChange={(e) =>
                  handleCheckboxChange(
                    index,
                    e.target.checked,
                    "recommendation",
                    el.recommendation
                  )
                }
              />
            </div>
            <Form.Group>
              <div>
                <h5>{el.recommendation}</h5>
              </div>
            </Form.Group>
          </Row>
        </div>
      ))}
      {showSuccessMessage && (
        <div className="alert alert-success" role="alert">
          Submission successful!
        </div>
      )}
      <Row
        className="justify-content-between align-items-center"
        style={{ marginTop: "60px" }}
      >
        <h4>Recommendation</h4>
        <Form.Group>
          <div>
            <Form.Check
              type="radio"
              id="defaultCheck-1"
              className="me-2"
              checked={
                selectedOptionRecommandation == "Accepted without revision"
              }
              onChange={() =>
                handleOptionRecommandationChange("Accepted without revision")
              }
              label="Accepted without revision"
            />
            <Form.Check
              type="radio"
              id="defaultCheck-2"
              checked={
                selectedOptionRecommandation == "Accepted with minor revision"
              }
              onChange={() =>
                handleOptionRecommandationChange("Accepted with minor revision")
              }
              label="Accepted with minor revision"
              className="mb-0"
            />
            <Form.Check
              type="radio"
              id="defaultCheck-3"
              checked={
                selectedOptionRecommandation == "Accepted with major revision"
              }
              onChange={() =>
                handleOptionRecommandationChange("Accepted with major revision")
              }
              label="Accepted with major revision"
              className="mb-0"
            />
            <Form.Check
              type="radio"
              id="defaultCheck-4"
              checked={
                selectedOptionRecommandation ==
                "Rejected and encourage re-submission"
              }
              onChange={() =>
                handleOptionRecommandationChange(
                  "Rejected and encourage re-submission"
                )
              }
              label="Rejected and encourage re-submission"
              className="mb-0"
            />
            <Form.Check
              type="radio"
              id="defaultCheck-5"
              checked={selectedOptionRecommandation == "Rejected"}
              onChange={() => handleOptionRecommandationChange("Rejected")}
              label="Rejected"
              className="mb-0"
            />
          </div>
        </Form.Group>

        {errors && errors?.acceptedPublicationEthics && (
          <Form.Text className="error-text">
            {errors.acceptedPublicationEthics.message}
          </Form.Text>
        )}
      </Row>
      <Form.Group id="abstract" style={{ marginTop: "40px" }}>
        <Form.Label>Comments to the Authors*</Form.Label>
        <Controller
          control={control}
          name="commentsToAuthors"
          // defaultValue={submissionStore.abstract}
          rules={{
            required: {
              value: true,
              message: "The Comments to the Authors field is mandatory",
            },
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Form.Control
              as="textarea"
              required
              type="text"
              placeholder="Comments to the Authors"
              value={adminComments}
              style={{ height: "100px" }}
              onChange={(e) => setAdminComments(e.target.value)}
              // {...field}
            />
          )}
        />
      </Form.Group>
      <Row
        className="justify-content-between align-items-center"
        style={{ marginTop: "60px" }}
      >
        <h4>Editor decision phrases</h4>
        <p>
          These phrases are available to be included in the decision letter. You
          can select one or more phrases.
        </p>
        <Form.Group>
          <div>
            <Form.Check
              type="checkbox"
              id="Check-1"
              className="me-2"
              value="Thank you for submitting you manuscript. However, your manuscript does not fit with the aim and scopes of this journal"
              checked={phrases.includes(
                "Thank you for submitting you manuscript. However, your manuscript does not fit with the aim and scopes of this journal"
              )}
              onChange={(e) =>
                handlePhrasesChange(e.target.checked, e.target.value)
              }
              label="Thank you for submitting you manuscript. However, your manuscript does not fit with the aim and scopes of this journal"
            />
            <Form.Check
              type="checkbox"
              id="Check-2"
              value="Your manuscript fits with the aim and scopes of this journal. However, it will be declined for publication due to the lack of novelty"
              checked={phrases.includes(
                "Your manuscript fits with the aim and scopes of this journal. However, it will be declined for publication due to the lack of novelty"
              )}
              onChange={(e) =>
                handlePhrasesChange(e.target.checked, e.target.value)
              }
              label="Your manuscript fits with the aim and scopes of this journal. However, it will be declined for publication due to the lack of novelty"
              className="mb-0"
            />
            <Form.Check
              type="checkbox"
              id="Check-3"
              value="The manuscript English should be improved. More work is needed to support your conclusion"
              checked={phrases.includes(
                "The manuscript English should be improved. More work is needed to support your conclusion"
              )}
              onChange={(e) =>
                handlePhrasesChange(e.target.checked, e.target.value)
              }
              label="The manuscript English should be improved. More work is needed to support your conclusion"
              className="mb-0"
            />
            <Form.Check
              type="checkbox"
              id="Check-4"
              value="Please revise you manuscript according to the attached reviewer's comments and upload the file within 21 days"
              checked={phrases.includes(
                "Please revise you manuscript according to the attached reviewer's comments and upload the file within 21 days"
              )}
              onChange={(e) =>
                handlePhrasesChange(e.target.checked, e.target.value)
              }
              label="Please revise you manuscript according to the attached reviewer's comments and upload the file within 21 days"
              className="mb-0"
            />
            <Form.Check
              type="checkbox"
              id="Check-5"
              value="Please revise you manuscript according to the attached reviewer's comments and upload the file within 7 days"
              checked={phrases.includes(
                "Please revise you manuscript according to the attached reviewer's comments and upload the file within 7 days"
              )}
              onChange={(e) =>
                handlePhrasesChange(e.target.checked, e.target.value)
              }
              label="Please revise you manuscript according to the attached reviewer's comments and upload the file within 7 days"
              className="mb-0"
            />
          </div>
        </Form.Group>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "40px",
        }}
      >
        <Button variant="secondary" className="text-gray" onClick={onSubmit}>
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default EditorFeedback;
