import { post } from "../utils/functions/httpMerthods";
import * as urlWebservice from "../utils/constants/apis";

export const loginService = async (payload) => {
  try {
    const result = await post(urlWebservice.LOGIN, payload);
    return result;
  } catch (error) {
    if (error && error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
