import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  ButtonGroup,
  Dropdown,
  Button,
} from "@themesberg/react-bootstrap";
import { AllVolsTable } from "../components/AllVolsTable";
import * as issuesService from "../services/issues.service";

export default () => {
  const [volList, setVolList] = useState([]);

  const getAllVolList = async () => {
    try {
      const result = await issuesService.getAllVols();
      console.log("result ====>", result);
      setVolList(result);
    } catch (error) {}
  };

  useEffect(() => {
    getAllVolList();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>All Vols</h4>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mr-0 mt-0 mb-0 m-3">
        <p></p>
        {/* <div>
          <Button variant="secondary" onClick={handleRedirect}>
            Add New Vol
          </Button>
        </div> */}
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      {volList && <AllVolsTable files={volList} />}
    </>
  );
};
