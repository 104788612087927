import * as urlWebservice from "../utils/constants/apis";
import { defaultOptions, get, post } from "../utils/functions/httpMerthods";

export const getUserInfo = async () => {
  try {
    const result = await get(urlWebservice.PROFILE_INFO, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const activateUser = async (id) => {
  try {
    const result = await post(
      `${urlWebservice.ACTIVATE_USER}/${id}`,
      {},
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const blockUser = async (id) => {
  try {
    const result = await post(
      `${urlWebservice.BLOCK_USER}/${id}`,
      {},
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
