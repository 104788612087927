import React from "react";
import { Row, Col, Card, Container } from "@themesberg/react-bootstrap";
import * as submissionService from "../services/submission.service";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASE_URL } from "../utils/constants/apis";

export default () => {
  const [data, setData] = useState();
  const { idFile, emailReviewer } = useParams();
  const [restTime, setRestTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const getInvitationById = async () => {
    try {
      const result = await submissionService.getInvitationDetalisById(
        idFile,
        emailReviewer
      );
      console.log("result ===>", result);
      setData(result);
    } catch (error) {}
  };

  useEffect(() => {
    getInvitationById();
  }, []);

  useEffect(() => {
    const originalDate = new Date(data?.sentDate);
    const updatedDate = new Date(
      originalDate.getTime() + 14 * 24 * 60 * 60 * 1000
    );
    const countdownDate = new Date(updatedDate).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setRestTime({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(interval);
        setRestTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <Container className="px-0">
      <Row>
        <Col xs={12} className="p-3">
          <Card>
            <Card.Body>
              <article>
                <h1 className="h2" id="overview">
                  Invitation Reviewrs Details
                </h1>
                {data && data?.message && (
                  <p className="fs-6 fw-light">
                    <span className="fw-bold">
                      No Invitation sent to this reviwer{" "}
                    </span>
                  </p>
                )}
                {data && data?.invitationStatus && (
                  <>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">First Name : </span>
                      {data && data.firstName}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Last Name : </span>{" "}
                      {data && data.lastName}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Email : </span>{" "}
                      {data && data.email}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Invitation Status : </span>{" "}
                      {data && data.invitationStatus}
                    </p>
                    <p className="fs-6 fw-light">
                      <span className="fw-bold">Sent Date : </span>{" "}
                      {data && data.sentDate}
                    </p>
                    {data?.invitationStatus === "pending" && (
                      <p className="fs-6 fw-light">
                        <span className="fw-bold">
                          The time remaining for the invitation to end :{" "}
                        </span>{" "}
                        <span className="fw-bold error-text">
                          {restTime.days} Days
                        </span>{" "}
                        <span className="fw-bold error-text">
                          {restTime.hours} Hours
                        </span>{" "}
                        <span className="fw-bold error-text">
                          {restTime.minutes} Minutes
                        </span>{" "}
                        <span className="fw-bold error-text">
                          {restTime.seconds} Seconds
                        </span>
                      </p>
                    )}
                  </>
                )}
              </article>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
