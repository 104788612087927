import React from "react";
import { Col, Row, Button, Dropdown } from "@themesberg/react-bootstrap";

import Profile3 from "../../assets/img/team/profile-picture-3.jpg";
import { EditorForm } from "./editorForm";

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>

      <Row>
        <h4>Add New Editor</h4>

        <Col xs={12} xl={12}>
          <EditorForm />
        </Col>
      </Row>
    </>
  );
};
