import * as urlWebservice from "../utils/constants/apis";
import { defaultOptions, get } from "../utils/functions/httpMerthods";

export const getSumbission = async () => {
  try {
    const result = await get(
      urlWebservice.GET_ALL_SUBMISSIONS,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSumbissionById = async (id) => {
  try {
    const result = await get(
      `${urlWebservice.GET_SUBMISSION_BY_ID}/${id}`,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const getInvitationDetalisById = async (idFile, emailReviewer) => {
  console.log(emailReviewer)  
  try {
    const result = await get(
      `${urlWebservice.GET_INVITATION_BY_ID}/${idFile}/${emailReviewer}`,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
