import {
  defaultOptions,
  deleteHttp,
  deleteHttpWithPayload,
  get,
  patch,
  post,
  put,
} from "../utils/functions/httpMerthods";
import * as urlWebservice from "../utils/constants/apis";

export const getAllVols = async () => {
  try {
    const result = await get(urlWebservice.GET_ALL_VOLS, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllIssues = async () => {
  try {
    const result = await get(urlWebservice.GET_ALL_ISSUES, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const addIssue = async (payload) => {
  try {
    const result = await post(urlWebservice.ISSUES, payload, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const hideShowIssue = async (id) => {
  try {
    const result = await patch(
      `${urlWebservice.HIDE_SHOW_ISSUES}/${id}`,
      {},
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteIssue = async (id) => {
  try {
    const result = await deleteHttp(
      `${urlWebservice.DELETE_ISSUE}/${id}`,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const addArtilceToIssue = async (id, payload) => {
  try {
    const result = await patch(
      `${urlWebservice.ADD_ARTICLE_ISSUE}/${id}`,
      payload,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteJournalFromIssue = async (id, payload) => {
  try {
    const result = await deleteHttpWithPayload(
      `${urlWebservice.DELETE_JOURNAL_ISSUE}/${id}`,
      payload,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const getIssueById = async (id) => {
  try {
    const result = await get(
      `${urlWebservice.GET_ALL_ISSUES}/${id}`,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const reoarderIssue = async (payload) => {
  try {
    const result = await put(
      `${urlWebservice.REORDER_ISSUE}`,
      payload,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
