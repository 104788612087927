import React, { useEffect, useState } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { defaultOptions } from "../utils/functions/httpMerthods";
import {
    Form,
    Nav,
    Card,
    Button,
    Table,
    Dropdown,
    Pagination,
    ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import FormCheckInput from "@themesberg/react-bootstrap/lib/esm/FormCheckInput";
import { Controller, useForm } from "react-hook-form";

export default () => {
    const [data, setData] = useState([]);
    console.log(data)
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [checkedUserId, setCheckedUserId] = useState([]);
    console.log(checkedUserId)

    const { id } = useParams();
    console.log(id)
    const history = useHistory();
    const handleRedirect = (path) => {
        history.push({ pathname: path });
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        const fetchData = async (id) => {
            try {
                const response = await fetch(`https://api.baejournal.com/api/v1/files/get-reviwed-submissions/${id}`, defaultOptions());
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(id);
    }, []);

    const handleCheckboxChange = (event, userId) => {
        const checked = event.target.checked;
        setIsCheckboxChecked(checked);
        if (checked) {
            setCheckedUserId(prevState => [...prevState, userId]);
        } else {
            setCheckedUserId(prevState => prevState.filter(id => id !== userId));
        }
    };

    const setUserId = () => {
        console.log(checkedUserId);
        // Add your logic to send feedback to the author with the IDs stored in checkedUserId
    };

    return (
        <>
            <Row className="justify-content-between align-items-center">
                <h5 className="mb-4">Comments</h5>
                <Form>
                    <Row>
                        <Col
                            md={12} className="mb-3">
                            <Form.Group id="abstract">
                                <Form.Label>Comments to the Authors*</Form.Label>
                                <Controller
                                    control={control}
                                    name="commentsToAuthors"
                                    // defaultValue={submissionStore.abstract}
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                "The Comments to the Authors field is mandatory",
                                        },
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Control
                                            as="textarea"
                                            required
                                            type="text"
                                            placeholder="Comments to the Authors"
                                            {...field}
                                        />
                                    )}
                                />
                                {/* {errors && errors?.commentsToAuthors && (
                                    <Form.Text className="error-text">
                                        {errors.commentsToAuthors.message}
                                    </Form.Text>
                                )} */}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3">
                            <Form.Group id="coverletter">
                                <Controller
                                    control={control}
                                    name="authorComment"
                                    // rules={{ required: "Please select a cover letter file" }}
                                    render={({ field }) => (
                                        <div>
                                            <div className="input-group custom-file-button">
                                                <label
                                                    className="input-group-text"
                                                    for="inputGroupFile"
                                                >
                                                    Choose File
                                                </label>
                                                <input
                                                    type="file"
                                                    accept=".doc,.docx"
                                                    // onChange={(e) => {
                                                    //     field.onChange(e);
                                                    //     handleAuthorCommentFileChange(e);
                                                    // }}
                                                    className="form-control"
                                                    id="inputGroupFile"
                                                />
                                            </div>
                                            {/* {field.value && (
                        <>
                          <p>
                            Selected file:{" "}
                            <span style={{ color: "blue" }}>
                              {submissionStore.coverLetter.name}
                            </span>
                          </p>
                        </>
                      )} */}
                                        </div>
                                    )}
                                />
                                {/* {errors && errors?.coverLetter && (
                  <Form.Text className="error-text">
                    {errors.coverLetter.message}
                  </Form.Text>
                )} */}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3">
                            <Form.Group id="abstract">
                                <Form.Label>Comments to the Editor</Form.Label>
                                <Controller
                                    control={control}
                                    name="commentsToEditor"
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Control
                                            as="textarea"
                                            required
                                            type="text"
                                            placeholder="Comments to the Editor"
                                            {...field}

                                        />
                                    )}
                                />
                                {/* {errors && errors?.commentsToEditor && (
                                    <Form.Text className="error-text">
                                        {errors.commentsToEditor.message}
                                    </Form.Text>
                                )} */}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md={12} className="mb-3">
                            <Form.Group id="maunscript">
                                <Controller
                                    control={control}
                                    name="editorComment"
                                    // rules={{ required: "Please select a manuscript file" }}
                                    render={({ field }) => (
                                        <div>
                                            {/* <Form.Control
                        required
                        type="file"
                        accept=".doc,.docx"
                        onChange={(e) => {
                          field.onChange(e);
                          handleMaunscriptFileChange(e);
                        }}
                      /> */}
                                            <div className="input-group custom-file-button">
                                                <label
                                                    className="input-group-text"
                                                    for="inputGroupFileMaunscript"
                                                >
                                                    Choose File
                                                </label>
                                                <input
                                                    type="file"
                                                    accept=".doc,.docx"
                                                    // onChange={(e) => {
                                                    //     field.onChange(e);
                                                    //     handleEditorCommentFileChange(e);
                                                    // }}
                                                    className="form-control"
                                                    id="inputGroupFileMaunscript"
                                                />
                                            </div>
                                            {field.value && (
                                                <p>
                                                    Selected file:{" "}
                                                    <span style={{ color: "blue" }}>
                                                        {/* {submissionStore.maunscript.name} */}
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    )}
                                />
                                {/* {errors && errors?.manuscript && (
                  <Form.Text className="error-text">
                    {errors.manuscript.message}
                  </Form.Text>
                )} */}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    );
};
