import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faCheck,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
  Modal,
  Form,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import * as reviewersService from "../services/reviewers.service";
import * as submissionService from "../services/submission.service";
import * as issuesService from "../services/issues.service";
import { useForm, Controller } from "react-hook-form";
import commands from "../data/commands";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { defaultOptions } from "../utils/functions/httpMerthods";
import Select from "react-select";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>{returnValue}</td>
        <td>
          <FontAwesomeIcon
            icon={bounceIcon}
            className={`${bounceTxtColor} me-3`}
          />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map((pv) => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const {
      id,
      source,
      sourceIcon,
      sourceIconColor,
      sourceType,
      category,
      rank,
      trafficShare,
      change,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon
            icon={sourceIcon}
            className={`icon icon-xs text-${sourceIconColor} w-30`}
          />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0"
                now={trafficShare}
                min={0}
                max={100}
              />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const AllSubmissionTable = (props) => {
  const { files } = props;

  const [showDefault, setShowDefault] = useState(false);
  const [showNewReviewerDefault, setShowNewReviewerdefault] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [fileId, setFileId] = useState(0);
  const [showAssignToIssueModal, setShowAssignToIssueModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [options, setOptions] = useState([]);

  const [selectedOptionsIssue, setSelectedOptionsIssue] = useState([]);

  const handleChangeIssue = (selected) => {
    setSelectedOptionsIssue(selected);
  };

  const handleClose = () => setShowDefault(false);
  const handleOpenNewReviewerModal = () => {
    setShowNewReviewerdefault(true);
  };
  const handleNewReviewerClose = () => {
    setShowNewReviewerdefault(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [newReviewerformData, setNewReviewerFormData] = useState({
    first_name: "",
    last_name: "",
    degree: "",
    institution: "", //optional
    email: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewReviewerFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleNewReviewerSubmit = async (e) => {
    e.preventDefault();
    const options = defaultOptions();
    // Prepare data object with form data
    const formData = {
      first_name: newReviewerformData.first_name.trim(),
      last_name: newReviewerformData.last_name.trim(),
      degree: newReviewerformData.degree,
      institution: newReviewerformData.institution,
      email: newReviewerformData.email,
    };
    console.log("formdata", formData);
    try {
      const response = await axios.post(
        `https://api.baejournal.com/api/v1/files/invite-reviwer/${fileId}`,
        formData,
        options
      );
      // Handle success
      console.log("Review added successfully:", response.data);
      // Reset form data after successful submission if needed
      setNewReviewerFormData({
        first_name: "",
        last_name: "",
        degree: "",
        institution: "",
        email: "",
      });
      setShowNewReviewerdefault(false);
      getAllReviewers(fileId);
    } catch (error) {
      // Handle error
      console.error("Error adding review:", error);
    }
  };

  const getAllReviewers = async (id) => {
    try {
      const { reviewers } = await submissionService.getSumbissionById(id);
      setReviewers(reviewers);
    } catch (error) {}
  };

  const handleOptionChange = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleAssignToIssueModalClose = () => {
    setShowAssignToIssueModal(false);
  };

  useEffect(() => {
    if (fileId !== 0) {
      getAllReviewers(fileId);
    }
  }, [fileId]);

  const assignReviewer = async () => {
    try {
      let payload = {
        usersIds: selectedOptions,
      };
      await reviewersService.assignReviewer(fileId, payload);
      setShowDefault(false);
    } catch (error) {}
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(files.map((file) => file.files_id.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const handleOpenAssignToIssueModal = () => {
    setShowAssignToIssueModal(true);
    getIssueList();
  };

  const getIssueList = async () => {
    try {
      const result = await issuesService.getAllIssues();
      const options = result.map((item) => ({
        value: item.id,
        label: item.coverStory,
      }));
      setOptions(options);
    } catch (error) {}
  };

  const assignToIssue = async () => {
    try {
      let payload = {
        filesIds: isCheck,
      };
      const result = await issuesService.addArtilceToIssue(
        selectedOptionsIssue.value,
        payload
      );
      handleAssignToIssueModalClose();
      window.location.reload();
    } catch (error) {}
  };

  const deleteFromIssue = async (issueId, fileId) => {
    try {
      let filesIds = {
        filesIds: [fileId],
      };
      const result = await issuesService.deleteJournalFromIssue(
        issueId,
        filesIds
      );
      window.location.reload();
    } catch (error) {}
  };
  const TableRow = (props) => {
    const history = useHistory();
    const {
      files_id: id,
      files_articleCode: index,
      files_articleType: articleType,
      files_fileStatus: fileStatus,
      files_funderName: funderName,
      files_grantNumber: grantNumber,
      files_issueId: issueId,
      email,
      isApproved,
    } = props;

    const handleRedirect = (path) => {
      history.push({ pathname: path });
    };
    const handleOpenModal = () => {
      setShowDefault(true);
      setFileId(id);
    };
    const statusVariant =
      fileStatus === "Approved"
        ? "success"
        : fileStatus === "Received" || fileStatus === "Under review"
        ? "warning"
        : fileStatus === "In press"
        ? "primary"
        : "primary";

    // const approveSubmission = async () => {
    //   try {
    //     await submissionService.approveSumbission(id);
    //     window.location.reload();
    //   } catch (error) {}
    // };
    // const deleteSubmission = async () => {
    //   try {
    //     console.log("first");
    //     await submissionService.deleteSumbission(id);
    //     window.location.reload();
    //   } catch (error) {}
    // };

    // const redirectToEditPage = () => {
    //   history.push({ pathname: `/edit-submission/${id}` });
    // };

    return (
      <tr>
        <td>
          {fileStatus === "Received" ||
            (fileStatus === "Under review" && (
              <input
                key={id}
                type="checkbox"
                id={id}
                onChange={handleClick}
                checked={isCheck.includes(id.toString())}
              />
            ))}
        </td>
        <td
          className="pointer-class"
          onClick={() => handleRedirect(`details-submission/${id}`)}
        >
          <Card.Link className="fw-normal">{index}</Card.Link>
        </td>
        <td
          className="pointer-class"
          onClick={() => handleRedirect(`details-submission/${id}`)}
        >
          <span className="fw-normal">{articleType}</span>
        </td>
        <td
          className="pointer-class"
          onClick={() => handleRedirect(`details-submission/${id}`)}
        >
          <span className="fw-normal">{email}</span>
        </td>
        <td className="pointer-class">
          <span className="fw-normal">{issueId ? "Yes" : "No"}</span>
        </td>
        <td
          className="pointer-class"
          onClick={() => handleRedirect(`details-submission/${id}`)}
        >
          <span className="fw-normal">{funderName}</span>
        </td>
        <td
          className="pointer-class"
          onClick={() => handleRedirect(`details-submission/${id}`)}
        >
          <span className="fw-normal">
            {grantNumber === null || grantNumber === ""
              ? 0
              : parseFloat(grantNumber).toFixed(2)}
          </span>
        </td>
        <td
          className="pointer-class"
          onClick={() => handleRedirect(`details-submission/${id}`)}
        >
          <span className={`fw-normal text-${statusVariant}`}>
            {fileStatus}
          </span>
        </td>
        <td>
          <Dropdown drop="left" as={ButtonGroup} className="m-2">
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="drop-down-list">
              <Dropdown.Item onClick={handleOpenModal}>
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Assign
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleRedirect(`emails-reviewrs-submission/${id}`)
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-2" />
                Reviewers List
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleRedirect(`emails-authors-submission/${id}`)
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-2" />
                Authors List
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleRedirect(`reviewer-response/${id}`)}
              >
                <FontAwesomeIcon icon={faEye} className="me-2" />
                Reviewer Feedback
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleRedirect(`editor-feedback/${id}`)}
              >
                <FontAwesomeIcon icon={faEye} className="me-2" />
                editor Feedback
              </Dropdown.Item>
              {issueId && (
                <Dropdown.Item onClick={() => deleteFromIssue(issueId, id)}>
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  Delete from Issue
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mr-0 mt-0 mb-4 m-3">
        <p></p>
        <div>
          {isCheck.length !== 0 && (
            <Button variant="secondary" onClick={handleOpenAssignToIssueModal}>
              Assign To Issue
            </Button>
          )}
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">
                  <input
                    type="checkbox"
                    id="selectAll"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                </th>
                <th className="border-bottom">Article Code</th>
                <th className="border-bottom">Article Type</th>
                <th className="border-bottom">Author Email</th>
                <th className="border-bottom">Issue</th>
                <th className="border-bottom">Funder Name</th>
                <th className="border-bottom">Grant Number</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Action</th>
              </tr>
            </thead>
            <tbody>
              {files.map((item, index) => {
                let file = {
                  ...item,
                  index,
                };
                return <TableRow key={index} {...file} />;
              })}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>Previous</Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>Next</Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{files.length}</b> out of <b>{files.length}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Assign Reviewrs</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <p>Select Reviewers</p>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              Select Options
            </Dropdown.Toggle>
            <Dropdown.Menu className="m-0 p-3" style={{ width: "400px" }}>
              <Form>
                <Form.Group>
                  {reviewers.length !== 0 &&
                    reviewers.map((option) => (
                      <Form.Check
                        key={option}
                        type="checkbox"
                        label={option.email}
                        disabled={
                          option.reviwer_status == "No invitation sent"
                            ? false
                            : true
                        }
                        value={option.id}
                        checked={selectedOptions.includes(option.id)}
                        onChange={() => handleOptionChange(option.id)}
                      />
                    ))}
                  <div
                    style={{
                      display: "flex",
                      "flex-direction": "row-reverse",
                      "margin-top": "20px",
                      gap: "15px",
                    }}
                  >
                    <Button
                      variant="secondary"
                      className="text-gray "
                      onClick={handleOpenNewReviewerModal}
                    >
                      + Add a reviewer
                    </Button>
                    <Button
                      variant="secondary"
                      className="text-gray"
                      onClick={assignReviewer}
                    >
                      Assign
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Close
          </Button>
          {/* <Button
            variant="secondary"
            className="text-gray"
            onClick={assignReviewer}
          >
            Assign
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* add new reviewer modal */}

      <Modal
        as={Modal.Dialog}
        centered
        show={showNewReviewerDefault}
        onHide={handleNewReviewerClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Add New Reviewer</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleNewReviewerClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Form method="post">
            <div style={{ display: "flex", gap: "40px" }}>
              <Form.Group controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <Controller
                  control={control}
                  name="first_name"
                  value={newReviewerformData.first_name}
                  rules={{
                    required: "First Name is required",
                    minLength: {
                      value: 2,
                      message: "First Name should be at least 2 characters",
                    },
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message: "First Name should contain only letters",
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Enter first name"
                      {...field}
                      onChange={handleChange}
                    />
                  )}
                />
                {errors.first_name && (
                  <Form.Text className="error-text">
                    {errors.first_name.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="last_name">
                <Form.Label>Last Name</Form.Label>
                <Controller
                  control={control}
                  name="last_name"
                  value={newReviewerformData.last_name}
                  rules={{
                    required: "Last Name is required",
                    minLength: {
                      value: 2,
                      message: "Last Name should be at least 2 characters",
                    },
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message: "Last Name should contain only letters",
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Enter last name"
                      {...field}
                      onChange={handleChange}
                    />
                  )}
                />
                {errors.last_name && (
                  <Form.Text className="error-text">
                    {errors.last_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <div style={{ display: "flex", gap: "40px" }}>
              <Form.Group controlId="degree">
                <Form.Label>Degree</Form.Label>
                <Controller
                  control={control}
                  name="degree"
                  value={newReviewerformData.degree}
                  rules={{
                    required: "Degree is required",
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Enter degree"
                      {...field}
                      onChange={handleChange}
                    />
                  )}
                />
                {errors.degree && (
                  <Form.Text className="error-text">
                    {errors.degree.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="institution">
                <Form.Label>Institution</Form.Label>
                <Controller
                  control={control}
                  name="institution"
                  value={newReviewerformData.institution}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Enter institution"
                      {...field}
                      onChange={handleChange}
                    />
                  )}
                />
              </Form.Group>
            </div>
            <div style={{ display: "flex", gap: "40px" }}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Controller
                  control={control}
                  name="email"
                  value={newReviewerformData.email}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      {...field}
                      onChange={handleChange}
                    />
                  )}
                />
                {errors.email && (
                  <Form.Text className="error-text">
                    {errors.email.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="mt-4 mb-2">
              <Button
                variant="link"
                className="text-gray ms-auto"
                onClick={handleNewReviewerClose}
              >
                Close
              </Button>
              <Button
                variant="secondary"
                className="text-gray"
                type="submit"
                onClick={handleNewReviewerSubmit}
              >
                Add
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* assign to issue */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showAssignToIssueModal}
        onHide={handleAssignToIssueModalClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Assign To Issue</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleNewReviewerClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Select your issue</Form.Label>
                <Select
                  options={options}
                  value={selectedOptionsIssue}
                  onChange={handleChangeIssue}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mr-0 mt-0 mb-4 m-0">
                <p></p>
                <Button variant="secondary" onClick={assignToIssue}>
                  Confirme
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
