import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faUserCheck,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "../routes";
import * as usersService from "../services/user.service";

function EmailsSubmission(props) {
  const { users, fileId } = props;
  const history = useHistory();

  console.log("fileId ===>", fileId);
  const totalUsers = users.length;

  const TableRow = (props) => {
    const { id, index, first_name, last_name, email } = props;

    const handleRedirect = (path) => {
      history.push({ pathname: path });
    };

    return (
      <tr
        className="pointer-class"
        onClick={() => handleRedirect(`/invitation-details/${fileId}/${email}`)}
      >
        <td>
          <Card.Link className="fw-normal">{index + 1}</Card.Link>
        </td>
        <td>
          <span className="fw-normal">{first_name}</span>
        </td>
        <td>
          <span className="fw-normal">{last_name}</span>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">First Name</th>
              <th className="border-bottom">Last Name</th>
              <th className="border-bottom">Email</th>
            </tr>
          </thead>
          <tbody>
            {users.length !== 0 &&
              users.map((item, index) => {
                let user = {
                  ...item,
                  index,
                };
                return <TableRow key={index} {...user} />;
              })}
          </tbody>
        </Table>
        {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalUsers}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
}

export default EmailsSubmission;
