import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faEllipsisH,
  faUserCheck,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "../routes";
import * as emailsService from "../services/emails.servies";

function EmailsTable(props) {
  const { emails } = props;
  const totalEmails = emails.length;
  const history = useHistory();
  const TableRow = (props) => {
    const {
      id,
      index,
      first_name,
      last_name,
      from,
      to: ToEmail,
      isApproved,
    } = props;
    const statusVariant = isApproved ? "success" : "danger";

    const approveEmail = async () => {
      try {
        await emailsService.approveEmail(id);
        window.location.reload();
      } catch (error) {}
    };

    const handleRedirect = () => {
      history.push({ pathname: `details-submission/${id}` });
    };

    return (
      <tr style={{ cursor: "pointer" }} onClick={handleRedirect}>
        <td>
          <Card.Link className="fw-normal">{index + 1}</Card.Link>
        </td>
        <td>
          <span className="fw-normal">{first_name}</span>
        </td>
        <td>
          <span className="fw-normal">{last_name}</span>
        </td>
        <td>
          <span className="fw-normal">{from}</span>
        </td>
        <td>
          <span className="fw-normal">{ToEmail}</span>
        </td>
        <td>
          <span className={`role-text-styling fw-normal text-${statusVariant}`}>
            {isApproved ? "Approved" : "Disapproved"}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            {!isApproved && (
              <Dropdown.Menu>
                <Dropdown.Item className="text-success" onClick={approveEmail}>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Approve
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">First Name</th>
              <th className="border-bottom">Last Name</th>
              <th className="border-bottom">From</th>
              <th className="border-bottom">To</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {emails.map((item, index) => {
              let user = {
                ...item,
                index,
              };
              return <TableRow key={index} {...user} />;
            })}
          </tbody>
        </Table>
        {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalEmails}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
}

export default EmailsTable;
