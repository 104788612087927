export const Routes = {
  // pages
  Presentation: { path: "/prensentation" },
  //   DashboardOverview: { path: "/dashboard/overview" },
  DashboardOverview: { path: "/dashboard" },
  Authors: { path: "/authors" },
  Reviewers: { path: "/reviewers" },
  AddReviewer: { path: "/create-reviewer" },
  // ReviewersEmails: { path: "/reviewers-emails" },
  Editors: { path: "/editors" },
  AddEditor: { path: "/create-editor" },
  // AuthorsEmails: { path: "/authors-emails" },
  AllSubmissions: { path: "/all-submissions" },
  AllVols: { path: "/all-vols" },
  AddVol: { path: "/add-vol" },
  AllIssues: { path: "/all-issues" },
  AddIssue: { path: "/add-issue" },
  AllJournals: { path: "/all-journals" },
  AddJournal: { path: "/add-journal" },
  IssueDetails: { path: "/issue-details/:id" },
  DetailsSubmission: { path: "/details-submission/:id" },
  InvitationDetails: { path: "/invitation-details/:idFile/:emailReviewer" },
  EmailsReviewrsSubmission: { path: "/emails-reviewrs-submission/:id" },
  EmailsAuthorsSubmission: { path: "/emails-authors-submission/:id" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },

  // reviewer feedback
  ReviewerResponse: { path: "/reviewer-response/:id" },
  ReviewerFeedbackDetails: {
    path: "/reviewer-response/reviewer-feedback-details/:fileId/:userId",
  },
  AdminFeedbacks: { path: "/reviewer-response/admin-feedback/:fileId/:userId" },

  //editor feedback
  EditorFeedback: { path: "/editor-feedback/:id" },
};
